import request from '@/utils/request'
import { getLoginAddress } from '@/utils/auth'

// 国家信息查询
export function queryStateList(params) {
  return request({
    url: `${getLoginAddress()}base/basestate/list`,
    method: 'get',
    params
  })
}
// 省份信息查询
export function queryProvinceList(params) {
  return request({
    url: `${getLoginAddress()}base/baseprovince/list`,
    method: 'get',
    params
  })
}

// 城市信息查询
export function queryCityList(params) {
  return request({
    url: `${getLoginAddress()}base/basecity/list`,
    method: 'get',
    params
  })
}
// 县区信息查询
export function queryCountyList(params) {
  return request({
    url: `${getLoginAddress()}base/basecounty/list`,
    method: 'get',
    params
  })
}

// style
export function queryStyleList(params) {
  return request({
    url: `${getLoginAddress()}base/basestyle/list`,
    method: 'get',
    params
  })
}

// color
export function queryColorList(params) {
  return request({
    url: `${getLoginAddress()}base/basecolor/list`,
    method: 'get',
    params
  })
}
// size
export function querySizeList(params) {
  return request({
    url: `${getLoginAddress()}base/basesize/list`,
    method: 'get',
    params
  })
}
export function getCargoOwner(params) {
  return request({
    url: `${getLoginAddress()}wms-base/cargoowner/getCargoOwner`,
    method: 'get',
    params
  })
}

export function getAllWarehouse(data) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse/getWarehouse`,
    method: 'post',
    data
  })
}

// 仓库信息查询
export function queryWarehouse(params) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse/page`,
    method: 'get',
    params
  })
}
// 仓库信息添加
export function addWarehouse(data) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse`,
    method: 'post',
    data
  })
}
// 仓库信息修改
export function modifyWarehouse(data) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse`,
    method: 'put',
    data
  })
}

// 仓库信息通过id查询
export function queryWarehouseId(id) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse/${id}`,
    method: 'get'
  })
}
/** 模板下载 */
export function findDownloadUrl(templateKey) {
  return request({
    url: `${getLoginAddress()}base/templatedownloadtask/findDownloadUrl/${templateKey}`,
    method: 'get'
  })
}
