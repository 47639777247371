import request from '@/utils/request'

// SKU维度
export function getSkuList(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/sku`,
    method: 'get',
    params
  })
}
// SKU库存明细
export function getSkuDetail(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/sku/detail`,
    method: 'get',
    params
  })
}

// 货主库存明细
export function getCargoOwnerDetail(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/cargoOwner/detail`,
    method: 'get',
    params
  })
}
// 仓库维度
export function getWarehouseDimension(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/warehouse`,
    method: 'get',
    params
  })
}
// 仓库维度
export function getWarehouseDetail(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/warehouse/detail`,
    method: 'get',
    params
  })
}
// 货主维度
export function cargoOwner(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/inventory/cargoOwner`,
    method: 'get',
    params
  })
}
