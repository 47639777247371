import request from '@/utils/request'

// 入库任务分页查询
export function pageTrayTask(params) {
  return request({
    url: `inbound/pageTrayTask`,
    method: 'get',
    params
  })
}

// 收货任务分页查询
export function pageCollectTask(params) {
  return request({
    url: `inbound/pageCollectTask`,
    method: 'get',
    params
  })
}

// 上架任务分页查询
export function pageShelfTask(params) {
  return request({
    url: `inbound/pageShelfTask`,
    method: 'get',
    params
  })
}
// 收货单明细查询
export function findCollectDetail(data) {
  return request({
    url: `inbound/findCollectDetail`,
    method: 'post',
    data
  })
}
// 上架单明细查询
export function findShelfDetail(data) {
  return request({
    url: `inbound/findShelfDetail`,
    method: 'post',
    data
  })
}
// 入库单明细查询
export function findTrayDetail(data) {
  return request({
    url: `inbound/findTrayDetail`,
    method: 'post',
    data
  })
}
// 入库单的入库明细导入
export function collectTaskTrayDetailImport(data) {
  return request({
    url: `inbound/collectTaskTrayDetailImport`,
    method: 'post',
    data,
    timeout: 999999
  })
}
// 入库单仓库查询 // VUE_APP_IMS_SC_API
export function queryWarehouseList(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/warehouse/getWarehouse`,
    method: 'get',
    params
  })
}
// 入库单仓库---查询---更细数据
export function getWarehouse(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/warehouse/getWarehouse`,
    method: 'get',
    params
  })
}
// 入库单仓库---查询---更细数据
export function completeCollectTask(params) {
  return request({
    url: `inbound/completeCollectTask`,
    method: 'get',
    params
  })
}

// 可用锁定库存
export function getPageSkuLockInventory(params) {
  return request({
    url: `/pageSkuLockInventory`,
    method: 'get',
    params
  })
}
// 发货在途库存
export function getPageSkuOnTheWay(params) {
  return request({
    url: `/pageSkuOnTheWay`,
    method: 'get',
    params
  })
}
// 待出库库存
export function getPageSkuWaitStock(params) {
  return request({
    url: `/pageSkuWaitStock`,
    method: 'get',
    params
  })
}
// 可用库存
export function skuAvailableStock(data) {
  return request({
    url: `/skuAvailableStock`,
    method: 'post',
    data
  })
}
