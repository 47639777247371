<template>
  <el-dialog
    title=" "
    :visible.sync="dialogVisible"
    width="1000px"
    :before-close="handleClose"
  >
    <div class="app-container">
      <el-row class="grid-content bg-purple-dark">
        <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
      </el-row>
      <el-form label-width="100px">
        <div>
          <el-row :span="24">
            <!-- <el-col :span="12">
              <el-form-item :class="$i18n.locale" label="仓库名称">
                {{ detailsPopupObj.logicWarehouseName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :class="$i18n.locale" label="所属物理仓">
                {{ detailsPopupObj.sourceWarehouseName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :class="$i18n.locale" label="货主">
                {{ detailsPopupObj.cargoOwnerCode }}</el-form-item>
            </el-col> -->
            <el-col v-for="item in detailsPopupBaseInfor" :key="item.key" :span="12">
              <el-form-item :class="$i18n.locale" :label="item.title">
                {{ detailsPopupObj[item.key] }}</el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-row class="grid-content bg-purple-dark" type="flex">
        <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
      </el-row>
      <el-form ref="addOutboundForm" :model="form" label-width="100px" @submit.native.prevent>
        <div class="el-lt" style="width:100%;">
          <el-row :span="24">
            <el-col :span="10">
              <el-form-item :class="$i18n.locale" label="关联单号" prop="orderId">
                <el-input v-model="form.orderId" clearable @keyup.enter.native="search('orderId')" />
              </el-form-item>
            </el-col>
            <el-col v-if="isShowBatchNo" :span="10">
              <el-form-item :class="$i18n.locale" label="入库批次号" prop="batchNo">
                <el-input v-model="form.batchNo" clearable @keyup.enter.native="search('batchNo')" />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4" style="margin-left: 15px;">
              <el-button type="primary" @click="search()">搜索</el-button>
            </el-col> -->
          </el-row>
        </div>
      </el-form>
      <el-table
        ref="tableData"
        v-loading="detailsPopupLoading"
        class="mb-3"
        :data="datas"
        show-summary
        :header-cell-style="{background:'#fafafa'}"
        max-height="550px"
      >
        <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="关联单号" align="center" />
        <el-table-column
          v-for="col in detailsPopupColumn"
          :key="col.key"
          :prop="col.key"
          :label="col.title"
          align="center"
        />
      </el-table>
      <Paging :pager="pager" @update="update" />
    </div>
  </el-dialog>
</template>
<script>
import Paging from '@/components/Paging'

export default {
  components: {
    Paging
  },
  props: {
    detailsPopupBaseInfor: { // 头部基本展示字段
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    detailsPopupList: { // 列表数据
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    detailsPopupColumn: { // 列表数据头部
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    detailsPopupObj: { // 基本信息-内容
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    detailsPopupPager: { // 列表-分页
      type: Object,
      required: true,
      default() {
        return {
          size: 20,
          current: 1,
          total: 0
        }
      }
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    isShowBatchNo: { // 入库批次号 是否展示
      type: Boolean,
      default: false
    },
    detailsPopupLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        orderId: '',
        batchNo: ''
      },
      pager: this.detailsPopupPager,
      datas: this.detailsPopupList
    }
  },
  watch: {
    detailsPopupList: {
      handler(newVal, oldVal) {
        this.datas = newVal
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    search(type) {
      const searchType = this.form[type]
      if (searchType) {
        this.datas = this.datas.filter(v => v[type] && (v[type] === searchType))
        this.pager = {
          size: 20,
          current: 1,
          total: 1
        }
      } else {
        this.$emit('getPageList', { current: 1, size: 20, ...this.detailsPopupObj })
      }
    },
    update(val) {
      this.pager = val
      this.$emit('getPageList', { ...val, ...this.detailsPopupObj, ...this.form })
    },
    handleClose(done) {
      this.form.orderId = ''
      this.$emit('offDialogVisible')
      done()
    }
  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}

</style>
